<template>
	<div class="Statistics">
		<form class="Query center" @submit.prevent>
			<div class="flex place-items-center">
				<select v-model="query.method">
					<option value="INVOICES-STATISTICS">INVOICES-STATISTICS</option>
					<option value="DEPOSITS-STATISTICS">DEPOSITS-STATISTICS</option>
				</select>

				<input-filter
					v-model="query.params['params.start']"
					:property="{}"
					label="Du"
					type="compare-date"
				/>

				<input-filter
					v-model="query.params['params.end']"
					label="au"
					:property="{}"
					type="compare-date"
				/>
			</div>
		</form>

		<div v-if="invoicesStats && query.method==='INVOICES-STATISTICS'" class="document invoices-statistics">
			<h1>INVOICES</h1>
			<div class="bilan">
				<div>excludingTaxAmount : {{ toCurrency(invoicesStats.excludingTaxAmount?.toFixed(2)) }}</div>
				<div>includingTaxAmount : {{ toCurrency(invoicesStats.includingTaxAmount?.toFixed(2)) }}</div>
				<div>amountPaid : {{ toCurrency(invoicesStats.amountPaid?.toFixed(2)) }}</div>
				<div>priceSum : {{ toCurrency(invoicesStats.priceSum?.toFixed(2)) }}</div>
			</div>


			<div class="property display-table">
				<label>Vue synthétique</label>
				<table class="table">
					<tbody>
						<tr>
							<th>shortTypeCode</th>
							<th>name</th>
							<th class="number">sold</th>
							<th class="number">price</th>
						</tr>
						<tr v-for="product in invoicesSumary" :key="product.shortTypeCode" class="document">
							<td>{{ product.shortTypeCode }}</td>
							<td>{{ product.name }}</td>
							<td class="number">{{ toNumber(product.sold) }}</td>
							<td class="number">{{ toCurrency(product.price) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div v-else-if="depositsStats && query.method==='DEPOSITS-STATISTICS'" class="document deposits-statistics">
			<h1>DEPOSITS</h1>

			<div class="property display-table">
				<label>Vue synthétique</label>
				<table class="table">
					<tbody>
						<tr>
							<th>shortTypeCode</th>
							<th>name</th>
							<th class="number">depositQuantity</th>
						</tr>
						<tr v-for="product in depositsSumary" :key="product.shortTypeCode" class="document">
							<td>{{ product.shortTypeCode }}</td>
							<td>{{ product.name }}</td>
							<td class="number">{{ toNumber(product.depositQuantity) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div v-else class="contentPlaceholder">
			<span class="material-symbols loader">progress_activity</span>
		</div>
	</div>
</template>

<script>

import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import Navigation from '@/stores/Navigation.js' ;

export default {
	setup: function() {
		Navigation().addEntry( {
			path: '/Statistics' ,
			title: 'Statistics' ,
			icon: 'bar_chart'
		} ) ;
		return {
			store: Collections( 'Organizations' )
		} ;
	} ,
	data: function() {
		return {
			invoicesStats: null ,
			invoicesSumary: [] ,

			depositsStats: null ,
			depositsSumary: [] ,
			query: {
				method: 'INVOICES-STATISTICS' ,
				limit: null ,
				params: {
					'params.start': '2023-01-01' ,
					'params.end': '2024-01-01'
				}
			}
		} ;
	} ,
	watch: {
		query: {
			handler: function() {
				this.getStats() ;
			} ,
			immediate: true ,
			deep: true
		}
	} ,
	methods: {
		getStats: function() {
			this.store.restQueryCollection.get( this.query )
				.then( stats => {
					if ( this.query.method === 'INVOICES-STATISTICS' ) this.setInvoices( stats ) ;
					if ( this.query.method === 'DEPOSITS-STATISTICS' ) this.setDeposits( stats ) ;
				} ) ;
		} ,
		setInvoices: function( stats ) {
			this.invoicesStats = stats ;
			var sumary = {} ;

			for( let [name , product] of Object.entries( stats.products ) ) {
				sumary[product.shortTypeCode] ??= {
					shortTypeCode: product.shortTypeCode ,
					name: `Groupe ${product.shortTypeCode}` ,
					sold: 0 ,
					price: 0
				} ;
				sumary[product.shortTypeCode].sold += product.sold || 0 ;
				sumary[product.shortTypeCode].price += product.price || 0 ;

				sumary[name] = {
					shortTypeCode: product.shortTypeCode ,
					name: product.name ,
					sold: product.sold || 0 ,
					price: product.price || 0
				} ;
			}

			this.invoicesSumary = Object.values( sumary ).sort( ( a , b ) => a.shortTypeCode > b.shortTypeCode ) ;
		} ,
		setDeposits: function( stats ) {
			this.depositsStats = stats ;
			var sumary = {} ;

			for( let [name , product] of Object.entries( stats.products ) ) {
				sumary[product.shortTypeCode] ??= {
					shortTypeCode: product.shortTypeCode ,
					name: `Groupe ${product.shortTypeCode}` ,
					depositQuantity: 0
				} ;
				sumary[product.shortTypeCode].depositQuantity += product.depositQuantity || 0 ;

				sumary[name] = {
					shortTypeCode: product.shortTypeCode ,
					name: product.name ,
					depositQuantity: product.depositQuantity || 0
				} ;
			}

			this.depositsSumary = Object.values( sumary ).sort( ( a , b ) => a.shortTypeCode > b.shortTypeCode ) ;
		} ,

		toCurrency: function( number ) {
			return new Intl.NumberFormat( 'fr-FR' , { style: 'currency' , currency: 'EUR' } ).format( number ) ;
		} ,
		toNumber: function( number ) {
			return new Intl.NumberFormat().format( number ) ;
		}
	}
} ;
</script>

<style src="./css/query.css" />


<style scoped>
.Statistics {
	margin: 2em auto 8em;
}
.number{
	text-align:right;
}
.document {
	width: 80%;
	margin: 2em auto;
	padding:2em;
	box-sizing: border-box;
    border: solid 1px var(--color-smooth);
    background-color: var(--color-background);
    border-radius: 1.2em 1.2em 1em 1em;
    border-top: none;
}
.display-table {
	.table{
		margin:auto ;

		width:100%;

		th{
			font-size: 0.9em;
			font-weight: 600;
			padding: 0 0.5em;
			background-color:var(--color-smooth);
			}

		td {
			padding:0 0.5em;
		}
		tr{
			border-radius:0em;
			box-shadow: 0 0 1px 0px var(--color-smooth);
		}
		tr:hover{
			box-shadow: 0 0 2px 2px var(--color-focus);
		}
	}
	label{
		font-size: 1.2em;
		padding: 0.5em;
		margin-top:2em;
		color: var(--color-text);
		font-family: 'Josefin Sans', sans-serif;
		display: block;
		border-bottom: 1px solid var(--color-smooth);
		border-radius: unset;
	}
}
</style>
