<template>
	<Query v-bind="$attrs" :filters="filters" />
</template>

<script>
import Query from '@/libRestQuery/vue/components/Query.vue' ;

export default {
	components: {
		Query
	} ,
	inheritAttrs: false ,
	data: function() {
		return {
			filters: [
				'archived' , 'isPaid' , 'client' , 'creationDate'
			]
		} ;
	}
} ;
</script>