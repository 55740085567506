<template>
	<div class="document">
		<section>
			<header>Avoir client</header>
			<div class="section-content">
				<div class="document-columns">
					<div class="grid-align">
						<input-combo v-model="value['client']" v-bind="auto('client')" />
						<input-combo v-model="value['creditNumber']" v-bind="auto('creditNumber')" />
						<input-combo v-model="value['invoiceNumber']" v-bind="auto('invoiceNumber')" />
						<input-combo v-model="value['creditDate']" v-bind="auto('creditDate')" />
					</div>
					<div class="grid-align">
						<input-combo v-model="value['organizationName']" v-bind="auto('organizationName')" />
						<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
						<input-combo v-model="value['address']" v-bind="auto('address')" />
						<input-combo v-model="value['zipCode']" v-bind="auto('zipCode')" />
						<input-combo v-model="value['city']" v-bind="auto('city')" />
						<input-combo v-model="value['country']" v-bind="auto('country')" />
					</div>
					<div />
					<div>
						<h2>Total</h2>
						<div class="grid-align">
							<input-combo v-model="value['excludingTaxAmount']" v-bind="auto('excludingTaxAmount')" />
							<input-combo v-model="value['vatAmount']" v-bind="auto('vatAmount')" />
							<input-combo v-model="value['includingTaxAmount']" v-bind="auto('includingTaxAmount')" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section v-if="!edit" class="meta">
			<div class="section-content grid-align">
				<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
				<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
				<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
				<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

