import I18n from './utils/I18n.js' ;
import Account from './Account.js' ;
import Collection from './Collection.js' ;

export default function RestQuery( domain , options ) {
	if ( ! domain ) {
		throw new Error( 'RestQuery: Invalid parameters' ) ;
	}

	this.domain = domain ; // import.meta.env.VITE_APP_API

	this.i18n = new I18n( options.i18n ) ;

	this.pendingFetch = new Map() ;

	this.account = new Account( this ) ;


	this.registerServiceWorker() ;
}

RestQuery.prototype.Collection = function( name ) {
	return new Collection( this , name ) ;
} ;

RestQuery.prototype.fetch = function( path , options = {} ) {
	path = encodeURI( path ) ;

	if ( ! path.startsWith( '/' ) ) {
		path = '/' + path ;
	}

	if ( options.queryString ) {
		path += '?' + options.queryString ;
	}

	var headers = {
		'content-type': 'application/json'
		// 'X-Grant': true
	} ;

	if ( typeof Headers !== 'undefined' ) {
		headers = new Headers( headers ) ;
	}

	var requestOptions = {
		headers: headers ,
		mode: 'cors' ,
		method: options.method || 'GET'
	} ;

	if ( this.account?.session?.token ) {
		requestOptions.headers.append( 'x-token' , this.account.session.token ) ;
	}


	var fetchMethod = fetch ;

	if ( options.body ) {
		// If File
		if ( options.body instanceof File || options.body instanceof Blob ) {
			requestOptions.body = options.body ;
			fetchMethod = this.fallbackXHR ;

			requestOptions.headers.set( 'content-type' , 'application/octet-stream' ) ;
			try {
				requestOptions.headers.set( 'content-disposition' , `attachment; filename="${options.body.name}"; filename*=utf-8''${encodeURI( options.body.name )}` ) ;
			}
			catch ( error ) {
				console.log( error ) ;
				let name = 'unknow.' + options.body.name.split( '.' ).pop() ;
				requestOptions.headers.set( 'content-disposition' , `attachment; filename="${name}"; filename*=utf-8''${encodeURI( options.body.name )}` ) ;
			}
		}
		// If JSON Object
		else if ( typeof options.body !== 'string' ) {
			requestOptions.body = JSON.stringify( options.body ) ;
		}
	}


	var isCacheable = !! ( requestOptions.method === 'GET' && ! requestOptions.body ) ;
	if ( isCacheable && this.pendingFetch.has( path ) ) {
		return this.pendingFetch.get( path ) ;
	}

	var pendingRequest = fetchMethod( `${this.domain}${path}` , requestOptions )
		.then( response => {
			if ( this.pendingFetch.has( path ) ) {
				this.pendingFetch.delete( path ) ;
			}

			if ( response.ok || response.statusText === 'OK' ) {
				return response.status !== 204 ? response.json() : {} ;
			}
			throw Error( `RestQuery Error: ${response.headers.get( 'X-Error-Message' )}` ) ;
		} )
		.catch( error => {
			console.log( `requestError: ${path}` ) ;
			throw Error( error ) ;
		} ) ;

	if ( isCacheable ) {
		this.pendingFetch.set( path , pendingRequest ) ;
	}

	return pendingRequest ;
} ;

RestQuery.prototype.fallbackXHR = function( path , options ) {
	return new Promise( function( resolve , reject ) {
		console.log( `fallbackXHR mode` ) ;

		if ( ! options.progressCallback ) options.progressCallback = event => {
			console.log( 'progressCallback' ) ;
			console.log( event ) ;
		} ;

		var xhr = new XMLHttpRequest() ;

		xhr.upload.addEventListener( 'progress' , options.progressCallback ) ;

		xhr.upload.addEventListener( 'load' , event => {
			if ( options.progressCallback ) {
				options.progressCallback( { loaded: event.loaded , total: event.total , finished: true } ) ;
			}
			resolve( {
				text: function() {
					return xhr.response ;
				} ,
				json: function() {
					return JSON.parse( xhr.response || null ) ;
				} ,
				ok: true ,
				status: xhr.status ,
				statusText: xhr.statusText
			} ) ;
		} ) ;

		xhr.upload.addEventListener( 'error' , () => {
			reject( {
				ok: false ,
				status: xhr.status ,
				statusText: xhr.statusText
			} ) ;
		} ) ;

		xhr.open( options.method , path ) ;

		for( var header of options.headers.entries() ) {
			xhr.setRequestHeader( header[0] , header[1] ) ;
		}

		xhr.send( options.body ) ;
	} ) ;
} ;


RestQuery.prototype.registerServiceWorker = async function() {
	if ( 'serviceWorker' in navigator ) {
		try {
			// const registration = await navigator.serviceWorker.register( '/src/libRestQuery/js/ServiceWorker.js' ) ;
			const registration = await navigator.serviceWorker.register( '/ServiceWorker.js' ) ;

			if ( registration.installing ) {
				console.log( 'Service worker installing' ) ;
			}
			else if ( registration.waiting ) {
				console.log( 'Service worker installed' ) ;
			}
			else if ( registration.active ) {
				console.log( 'Service worker active' ) ;
			}

			navigator.serviceWorker.addEventListener( 'message' , ( event ) => {
				console.log( event.data ) ;
			} ) ;
		}
		catch ( error ) {
			console.error( `Registration failed with ${error}` ) ;
		}
	}
} ;