<template>
	<router-link v-if="schema && value" :style="{backgroundColor:value['color']}" class="value link" :to="link">
		<input-combo
			v-for="property in propertyList"
			:key="property.name"
			v-model="localValue[property.name]"
			:original-value="localValue[property.name]"
			:property="property"
		/>
	</router-link>
	<span v-else class="value">N/A</span>
</template>

<script>

import strings from '@/libRestQuery/js/utils/strings.js' ;
import mixin from './mixin' ;
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;

export default {
	mixins: [mixin] ,
	inject: {
		originalDocument: {
			from: 'document'
		}
	} ,
	props: {
		modelValue: {
			type: Object ,
			default: () => {}
		} ,
		properties: {
			type: Array ,
			default: () => ['hid']
		} ,
		linkTo: {
			type: String ,
			default: 'document'
		}
	} ,
	setup( props , context ) {
		let autoload = !! ( props.originalValue && ! props.properties.filter( property => {
			return typeof props.originalValue[property] !== 'undefined' ;
		} ).length ) ;

		return SetupDocument( props , context , {
			collection: props.originalValue?._collection || props.property.collection ,
			document: props.originalValue?._id ,
			autoload: autoload
		} ) ;
	} ,
	computed: {
		link: function() {
			return this.linkTo === 'filter' ? this.linkToFilter : this.linkToDocument ;
		} ,
		linkToDocument: function() {
			return {
				params: {
					app: this.collectionName ,
					document: this.localValue.slugId || this.localValue._id
				}
			} ;
		} ,
		linkToFilter: function() {
			return {
				params: {
					app: strings.capitalize( this.originalDocument._collection ) ,
					document: ''
				} ,
				query: {
					[`.${this.property.name}.$in`]: `[${this.value._id}]`
				}
			} ;
		} ,
		localValue: function() {
			return this.document || this.value ;
		} ,
		collectionName: function() {
			return strings.capitalize( this.modelValue?._collection || this.property.collection ) ;
		} ,
		propertyList: function() {
			return this.properties.map( property => this.schema.properties[property] ) ;
		} ,
		getColumnsLength: function() {
			return this.propertyList.length ;
		}
	}
} ;
</script>
