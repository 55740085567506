import { defineStore } from 'pinia' ;

export default defineStore( 'AppList' , {
	state: () => (
		[
			{
				name: 'Home' ,
				icon: 'home' ,
				dashboard: false
			} ,
			{
				path: 'Users?limit=30&sort.lastVisit=-1' ,
				name: 'Import:OK Filtres:OK Cards:OK Form:OK' ,
				icon: 'person' ,
				sidebar: false
			} ,
			{
				path: 'Products' ,
				name: 'Import:OK Filtres:OK Cards:OK Form:OK' ,
				icon: 'diamond'
			} ,
			{
				path: 'Carousels?limit=30&sort.year=-1' ,
				name: 'Import:OK Filtres:OK Cards:no Form:OK' ,
				icon: 'inventory' ,
				sidebar: false
			} ,
			{
				path: 'Organizations?limit=30&sort.modificationDate=-1' ,
				name: 'Import:no Filtres:no Cards:OK Form:%' ,
				icon: 'corporate_fare'
			} ,
			{
				path: 'Deposits?.state.$in=[preparing]&limit=30&sort.hid=1' ,
				name: 'Import:OK Filtres:no Cards:OK Form:OK' ,
				icon: 'matter' ,
				sidebar: false
			} ,
			{
				path: 'Deposits?.state.$in=[delivered]&limit=30&sort.hid=1' ,
				name: 'Import:OK Filtres:no Cards:OK Form:OK' ,
				icon: 'local_shipping'
			} ,
			{
				path: 'Returns?.state.$in=[requested]&limit=30&sort.collectingWeek=1' ,
				name: 'Import:OK Filtres:no Cards:OK Form:%' ,
				icon: 'undo'
			} ,
			{
				path: 'Invoices?limit=30&sort.invoiceDate=-1' ,
				name: 'Import:no Filtres:no Cards:OK Form:%' ,
				icon: 'request_quote'
			} ,
			{
				path: 'Payments' ,
				name: 'Import:no Filtres:no Cards:OK Form:OK' ,
				icon: 'payments' ,
				sidebar: false
			} ,
			{
				path: 'Credits' ,
				name: 'Import:no Filtres:no Cards:OK Form:no' ,
				icon: 'receipt_long' ,
				sidebar: false
			} ,
			{
				path: 'Tags?limit=30&sort.hid=-1' ,
				name: 'Import:OK Filtres:OK Cards:OK Form:OK' ,
				icon: 'Sell' ,
				sidebar: false
			} ,
			{
				path: 'Notes' ,
				name: 'Import:no Filtres:no Cards:no Form:no' ,
				icon: 'chat'
			} ,
			{
				name: 'Statistics' ,
				icon: 'bar_chart'
			} ,
			{
				name: 'Tests' ,
				icon: 'bug_report' ,
				sidebar: false
			} ,
			{
				name: 'Infos' ,
				icon: 'help' ,
				sidebar: false
			} ,
			{
				name: 'Account' ,
				icon: 'settings' ,
				sidebar: false
			}
		]
	)
} ) ;
